// disable scroll updates on page change / refresh
// https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#shouldUpdateScroll
export const shouldUpdateScroll = () => [0, 0]

export const onClientEntry = () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ dimension10: 'e-card' })
}

export { wrapRootElement } from './src/root-wrapper'
